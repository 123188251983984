import { DataSourceJsonData, DataSourceSettings } from '@grafana/data';
import { ESVersion } from 'provisioning/opensearch';

export type InstanceManagementSettings = {};

export interface ProvisioningAppSettings {
  serviceConfig: ProvisioningAppServiceConfig;
}

export interface ProvisioningAppServiceConfig {
  cloudwatch?: CloudWatchConfig;
  prometheus?: PrometheusConfig;
  athena?: AthenaConfig;
  redshift?: RedshiftConfig;
  twinmaker?: TwinMakerInfo;
}

export interface CloudWatchConfig {
  customMetricsNamespaces?: string;
}

export interface PrometheusConfig {
  timeInterval?: string;
  queryTimeout?: string;
}

export interface AthenaConfig {
  catalog?: string;
  database?: string;
  workgroup?: string;
}

export interface RedshiftConfig {
  database?: string;
  secret?: string;
}

export interface ServiceInfo {
  id: AWSServiceType;
  name: string;
  plugin?: string;
  enabled?: boolean;
  isFlat?: boolean;
}

export interface Region {
  id: string;
  name: string;
  endpoint: string;
}

export interface ServiceResource {
  id: string;
  arn: string;
}

export interface AWSAccount {
  arn: string;
  email: string;
  id: string;
  name: string;
  status: 'ACTIVE' | 'SUSPENDED';
}

export interface Resource {
  cloudwatchInfo: CloudwatchInfo;
  accountId: string;
  name?: string;
  endpoint: string;
  arn: string;
  id: string;
  regionId: string;
  athenaInfo?: AthenaInfo;
  redshiftInfo?: RedshiftInfo;
  twinMakerInfo?: TwinMakerInfo;
  openSearchInfo?: OpenSearchResource;
}

export type TableData = {
  account?: string;
  region?: string;
  index?: string;
  workspace?: string;
  service?: AWSServiceType;
  athenaInfo?: AthenaInfo;
  redshiftInfo?: RedshiftInfo;
  twinMakerInfo?: TwinMakerInfo;
  openSearchInfo?: OpenSearchInfo;
};

export type InstallableData = {
  resourceId?: string;
  endpoint?: string;
  index?: string;
  account?: AWSAccount;
  region: string;
  service?: AWSServiceType;
  athenaInfo?: AthenaInfo;
  redshiftInfo?: RedshiftInfo;
  twinMakerInfo?: TwinMakerInfo;
  openSearchInfo?: OpenSearchInfo;
  cloudwatchInfo?: CloudwatchInfo;
};

export type DataSourceTableData = {
  dataSourceName?: string;
  dataSource: AWSDataSourceSettings<any>;
  index?: string;
};

export type DataType = 'account' | 'workspace' | 'domain';

export enum AWSServiceType {
  CloudWatch = 'cloudwatch',
  Elasticsearch = 'elasticsearch',
  OpenDistro = 'opendistro',
  OpenSearch = 'opensearch',
  Prometheus = 'prometheus',
  SiteWise = 'sitewise',
  TimeStream = 'timestream',
  XRay = 'xray',
  Athena = 'athena',
  Redshift = 'redshift',
  TwinMaker = 'twinmaker',
}

export enum AWSDataSourceType {
  CloudWatch = 'cloudwatch',
  Elasticsearch = 'elasticsearch',
  OpenDistro = 'grafana-es-open-distro-datasource',
  OpenSearch = 'grafana-opensearch-datasource',
  Prometheus = 'prometheus',
  SiteWise = 'grafana-iot-sitewise-datasource',
  TimeStream = 'grafana-timestream-datasource',
  XRay = 'grafana-x-ray-datasource',
  Athena = 'grafana-athena-datasource',
  Redshift = 'grafana-redshift-datasource',
  TwinMaker = 'grafana-iot-twinmaker-datasource',
}

export type AWSDataSourceSettings<T> = Partial<DataSourceSettings<T>>;

export enum AWSDataSourceProvisioner {
  ProvisioningApp = 'aws-datasource-provisioner-app',
}

export interface AWSDataSourceJsonData extends DataSourceJsonData {
  provisionedBy?: AWSDataSourceProvisioner;
  isMonitoring?: boolean;
  accountId?: string;
}

export interface CreateDataSourceParams {
  account?: AWSAccount;
  roleName?: string;
  region: string;
}

export type AthenaInfo = {
  catalog: string;
  database: string;
  workgroup: string;
};

export type RedshiftInfo = {
  database: string;
  dbUser: string;
};

export type TwinMakerInfo = {
  workspaceId: string;
  workspaceDescription: string;
  dashboardRole: string;
};

export type OpenSearchResource = {
  serverless: boolean;
  esVersion?: string;
};

export type OpenSearchInfo = {
  serverless: boolean;
  esVersion?: ESVersion;
};

export type CloudwatchInfo = {
  isMonitoring: boolean;
};
